<template>
  <div class="cart-stock-tips">
    <div
      v-if="item.isGiftItem"
      class="cart-item__insufficientStock"
      :class="{ 'cart-item__few': !cartDiscountLogoA }"
    >
      <span>{{ language.SHEIN_KEY_PC_25239 }}</span>
    </div>
    <!-- 
      stock_show_type
      0-展示真实库存，1-展示库存不足提示，2-不展示库存
      -->
    <div
      v-else-if="item.stock_show_type == 1 && CART_STOCK_TIPS_SWITCH"
      class="cart-item__insufficientStock"
    >
      <span>{{ language.SHEIN_KEY_PC_21054 }}</span>
    </div>
    <div
      v-else-if="item.stock_show_type == 0 && +item.realTimeInventory > 0"
      class="cart-item__insufficientStock"
      :class="{
        'cart-item__few': !cartDiscountLogoA,
        'cart-item__flash': !cartDiscountLogoA && isFlashSaleItem && [1, 4, 5].includes(Number(item.flashType))
      }"
    >
      <span v-if="isFlashSaleItem && flashSaleItemNum">
        {{ template(flashSaleItemNum, language.SHEIN_KEY_PC_25797) }}
      </span>
      <span v-else>{{ template(item.realTimeInventory, language.SHEIN_KEY_PC_25797) }}</span>
    </div>
  </div>
</template>

<script>
import { template } from '@shein/common-function'
export default {
  props: {
    language: {
      type: Object,
      default: () => {
        return {}
      }
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    locals: {
      type: Object,
      default: () => {
        return null
      }
    },
    cartDiscountLogoA: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    CART_STOCK_TIPS_SWITCH () {
      return this.locals?.CART_STOCK_TIPS_SWITCH || gbCommonInfo.CART_STOCK_TIPS_SWITCH
    },
    isFlashSaleItem () {
      return this.item.promotionTypeIds.includes(10)
    },
    flashSaleItemNum () {
      // wiki.pageId=843279127
      let promotionFlash = this.item.product.product_promotion_info.filter(item => item.type_id == 10)
      const { stock = 0, sold_num = 0 } = promotionFlash[0] || {}
      const realTimeInventory = +this.item.realTimeInventory || 0
      const flashNum =  stock - sold_num
      const minNum = Math.min(realTimeInventory, flashNum)
      const maxNum = Math.max(realTimeInventory, flashNum)
      return minNum > 0 ? minNum : maxNum
    },
  },
  methods: {
    template: template,
  }
}
</script>

<style lang="less">
  .cart-stock-tips .cart-item__insufficientStock span {
    text-align: center;
  }
  .cart-item__insufficientStock {
    position: absolute;
    bottom: 0;
    background: rgba(0,0,0,.6);
    padding: 4px;
    line-height: 1;
    color: #fff;
    width: 100%;
    font-weight: bold;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    &.cart-item__few {
      background: rgba(250, 99, 56, 0.8);
      & when (@IS_RW){
        background: rgba(255, 105, 110, 0.8);
      }
      &.cart-item__flash {
        background: rgba(252, 211, 100, 0.9);
        & when (@IS_SH){
          color: #222;
        }
        & when (@IS_RW){
          background: rgba(255, 80, 120, 0.8);
        }
      }
    }
  }
</style>
