<template>
  <div
    v-if="stockTip.tip"
    :class="['cart-item__insufficientStock', stockTip.className]"
  >
    <span v-html="stockTip.tip"></span>
  </div>
</template>
<script setup>
import { defineProps, computed } from 'vue'
import { template } from '@shein/common-function'

const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  config: {
    type: Object,
    default: () => ({})
  },
  ctx: {
    type: Object,
    default: () => ({})
  }
})

const stockTip = computed(() => {
  return getStockBar(props.item, props.config, props.ctx)
})

function getStockBar(item, config = {}, ctx = {}) {
  const {
    language = {},
    soldoutIds = [],
    locals = {},
    flashSaleType = 0,
    flashSaleItemNum = 0,
    cartDiscountLogoA = false,
  } = ctx || {}
  let fewClassName = cartDiscountLogoA ? '' : 'cart-item__few'
  // 告罄/下架
  if (item.product.is_on_sale == '0' || soldoutIds.includes(item.id)) {
    if (item.isInvalid) {
      return {
        tip: language.SHEIN_KEY_PC_30888,
        className: ''
      }
    } else {
      return {
        tip: language.SHEIN_KEY_PC_14553,
        className: ''
      }
    }
  } else if (config?.quantity) return {
    tip: 'x' + config.quantity,
    className: config.quantity > 1 ? 'cart-item__quantity' : 'cart-item__quantity-low'
  }
  else if (item.isGiftItem) return {
    // 赠品腰带不属于库存信息，不受abt控制
    tip: language.SHEIN_KEY_PC_25239,
    className: item.isSheinClubGiftItem ? 'cart-item__club-gift' : 'cart-item__few'
  }
  else if (config?.showAddon && item.isAddItem) return {
    tip: language.SHEIN_KEY_PC_29518,
    className: 'cart-item__add-on'
  }
  /* 
  stock_show_type
  0-展示真实库存，1-展示库存不足提示，2-不展示库存
   */
  else if (item.stock_show_type == 1 && locals.CART_STOCK_TIPS_SWITCH) return {
    tip: language.SHEIN_KEY_PC_21054,
    className: ''
  }
  else if (item.stock_show_type == 0 && +item.realTimeInventory > 0) {
    let flashClassName = [-1, 1, 4, 5].includes(flashSaleType) && !cartDiscountLogoA ? 'cart-item__flash' : ''
    if (flashSaleType && flashSaleItemNum) return {
      tip: template(flashSaleItemNum, language.SHEIN_KEY_PC_25797),
      className: `${fewClassName} ${flashClassName}`
    }
    else return {
      tip: template(item.realTimeInventory, language.SHEIN_KEY_PC_25797),
      className: `${fewClassName} ${flashClassName}`
    }
  } else return {}
}
</script>
<style lang="less" scoped>
.cart-item__insufficientStock {
  position: absolute;
  bottom: 0;
  background: rgba(0,0,0,.6);
  padding: 4px;
  line-height: 1;
  color: #fff;
  width: 100%;
  font-weight: bold;
  text-align: center;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: center;
  }
  &.cart-item__few {
    background: rgba(250, 99, 56, 0.8);
    & when (@IS_RW){
      background: rgba(255, 105, 110, 0.8);
    }
    &.cart-item__flash {
      background: rgba(252, 211, 100, 0.9);
      & when (@IS_SH){
        color: #222;
      }
      & when (@IS_RW){
        background: rgba(255, 80, 120, 0.8);
      }
    }
  }
  &.cart-item__add-on {
    background: @sui_color_micro_emphasis_bg;
    color: @sui_color_micro_emphasis;
  }
  &.cart-item__quantity {
    background: rgba(0, 0, 0, 0.5);
    color: @sui_color_club_orange;
  }
  &.cart-item__quantity-low {
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
  }
  &.cart-item__club-gift {
    background: rgba(135, 60, 0, 0.80);
  }
}
</style>
