<template>
  <div
    da-event-expose="1-7-1-18"
    da-event-click="1-7-1-19"
    :data-store-code="item.store_code"
    :data-store-tp="item.store_type == 1 ? 1 : 2"
    :data-preferred-seller="item.preferred_seller_store == 1 ? 1 : 0"
    class="cart-item-store"
    :class="{'pointer': showJump}"
    @click="goToStore"
  >
    <PreferredSellerStoreIcon 
      v-if="item.preferred_seller_store == 1"
      :width="cssConfig.preferredSellerWidth"
      :height="cssConfig.preferredSellerHeight"
      :public-cdn="publicCdn"
    />
    <sui_icon_store_16px_1
      v-else
      :size="cssConfig.storeIconFontSize + 'px'"
    />
    <span 
      class="title"
      :style="{
        'font-size': cssConfig.textFontSize + 'px',
        'font-weight': cssConfig.fontWeight
      }"
    >
      {{ storeTitle }}
    </span>
    <sui_icon_more_right_12px_1
      v-if="showJump"
      size="12px"
      :is-rotate="GBCssRight"
    />
  </div>
</template>

<script>
import PreferredSellerStoreIcon from 'public/src/pages/cart_new/components/preferredSellerStoreIcon.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { sui_icon_more_right_12px_1, sui_icon_store_16px_1 } from '@shein-aidc/icon-vue3'
daEventCenter.addSubscriber({ modulecode: '1-7-1' })

export default {
  name: 'CartItemStore',
  components: {
    PreferredSellerStoreIcon,
    sui_icon_more_right_12px_1,
    sui_icon_store_16px_1
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    showOBMStore: {
      type: Boolean,
      default: true,
    },
    showThreeStore: {
      type: Boolean,
      default: true,
    },
    storeSelectsInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    cssConfig: {
      type: Object,
      default() {
        return {}
      }
    },
    locals: {
      type: Object,
      default() {
        return null
      }
    }
  },
  computed: {
    publicCdn() {
      return this.locals?.PUBLIC_CDN || gbCommonInfo.PUBLIC_CDN || ''
    },
    siteName () {
      return this.locals?.IS_RW || gbCommonInfo.IS_RW ? 'ROMWE' : 'SHEIN'
    },
    storeTitle() {
      if (this.showOBMStore) {
        return this.item.store_title
      }

      return this.showThreeStore && this.item.business_model == 1 ? this.item.store_title : this.siteName
    },
    showJump() {
      return this.item.store_code != 1 && this.storeTitle != this.siteName
    },
    GBCssRight() {
      return this.locals?.GB_cssRight || gbCommonInfo.GB_cssRight
    }
  },
  methods: {
    goToStore() {
      if (!this.showJump || !this.storeSelectsInfo[this.item.store_code]) return
      window.open(this.storeSelectsInfo[this.item.store_code], '_blank' )
    }
  },
}
</script>

<style lang="less" scoped>
.cart-item-store {
  display: inline-flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  &.pointer {
    cursor: pointer;
  }
  
  .title {
    margin: 0 2px;
    color: #000;
    overflow : hidden;
    text-overflow: ellipsis;
  }

  .sui_icon_more_right_12px_1 {
    transform: rotateZ(0deg)/* rtl: rotateZ(180deg) */;
  }

}
</style>
